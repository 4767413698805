@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    [type='text'],
    [type='email'],
    [type='url'],
    [type='password'],
    [type='number'],
    [type='date'],
    [type='datetime-local'],
    [type='month'],
    [type='search'],
    [type='tel'],
    [type='time'],
    [type='week'],
    textarea,
    select,
    select[multiple] {
        @apply rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent;
    }

    [type='checkbox'],
    [type='radio'] {
        @apply h-5 w-5 rounded-sm text-blue-500 focus:ring-2 focus:ring-blue-500 focus:border-blue-500;
    }

    body {
        @apply bg-gray-50;
    }
}

@layer components {
    button {
        @apply rounded-md p-2 focus:outline-none;
    }
}
